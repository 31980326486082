import React, { Fragment, useEffect, useState } from 'react';

import { SettingOutlined } from '@ant-design/icons';

import Link from 'components/atoms/Link';
import Table from 'components/organisms/Table/ReactTable/AsyncTable';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import Routes from 'constants/Routes';
import { APItoFront, column, TableParams } from 'utils/CreateQueries';
import { FilesHistoryBuilder, FilesHistoryProps, FormatFilesHistory } from 'models/FilesHistoryListBuilder';
import { QueryToState, QueryToString } from 'utils/CreateQueries/query';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Button, Input } from 'antd';

const FilesHistory = () => {
    const [filesHistory, setfilesHistory] = useState<FilesHistoryProps[]>(FilesHistoryBuilder());
    const [filter, setFilter] = useState<TableParams>(params);
    const [pageCount, setPageCount] = useState<number>(1);
    const [loadind, setLoading] = useState<boolean>(true);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [recherche, setRecherche] = useState<string>();
    const [, setSearchParams] = useSearchParams();
    const search = useLocation().search;

    const setQueryToState = () => {
        const linkedQuery = QueryToState(search);
        const localColumn: string = linkedQuery.columnToOrder;
        const filterState = {
            pageIndex: linkedQuery.numPage,
            pageSize: linkedQuery.nbrElements,
            sortBy: [{ id: APItoFront[localColumn], desc: !linkedQuery.isAscendingOrder }],
            globalFilter: linkedQuery.search,
        };
        setFilter(filterState);
        GetFiles(search);
    };

    useEffect(() => {
        setQueryToState();
    }, []);

    const GetFiles = async (queries: string, rechercheXML?: string) => {
        if (rechercheXML) {
            queries = queries.replace('&search=', `&rechercheXML=${rechercheXML}`);
        }
        const { status, nbTotalRows, ...response } = await GET(API.FILES_HISTORY + queries);
        if (!status) return setfilesHistory(FilesHistoryBuilder());
        setfilesHistory(FormatFilesHistory(response));
        setTotalRows(nbTotalRows);
        setPageCount(Math.round(nbTotalRows / filter.pageSize));
        setLoading(false);
    };

    const StateToQuery = (filter: any) => {
        const BackQuery = {
            nbrElements: filter.pageSize,
            numPage: filter.pageIndex,
            search: !filter.globalFilter ? '' : filter.globalFilter,
            isAscendingOrder: filter.sortBy[0].desc ? 0 : 1,
            columnToOrder: column[filter.sortBy[0].id],
        };
        setSearchParams(QueryToString(BackQuery));
        GetFiles(QueryToString(BackQuery));
    };

    const SETFILTER = (newParams: any) => {
        setFilter((curr: any) => ({
            ...curr,
            ...newParams,
            pageIndex: newParams?.pageIndex ? newParams.pageIndex : 0,
        }));
        StateToQuery({ ...filter, ...newParams, pageIndex: newParams?.pageIndex ? newParams.pageIndex : 0 });
    };

    return (
        <section className="container mx-auto">
            <div className="flex items-center mt-5">
                <Input
                    value={recherche}
                    placeholder="Rechercher dans le xml"
                    className="!w-[70%]"
                    onChange={({ target }) => setRecherche(target.value)}
                    allowClear
                    onClear={() => {
                        setRecherche('');
                        GetFiles(search);
                    }}
                />

                <Button
                    form="promotionForm"
                    className="mr-5 ml-5 !border-purple-700 !bg-purple-700 !font-bold"
                    type="primary"
                    onClick={() => GetFiles(search, recherche)}
                >
                    Rechercher dans le contenu
                </Button>
            </div>

            <Table
                columns={FilesHistoryColumn}
                data={filesHistory}
                loading={loadind}
                pageCount={pageCount}
                nbTotalRows={totalRows}
                defaultParams={filter}
                SETFILTER={SETFILTER}
            />
        </section>
    );
};

const SourceCell = (props: { value: string; row: { original: { tiersId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.COMPANY}/${props.row.original.tiersId}`}>
            <p className="text-sm text-left text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const FileCell = (props: { value: string; row: { original: { id: string } } }) => (
    <div className="flex">
        <Link to={`${Routes.FILES_HISTORY}/${props.row.original.id}`}>
            <p className="text-sm text-left text-purple-900 hover:underline">{props.value}</p>
        </Link>
        <Link to={`${Routes.FILES_HISTORYBRUT_ID}?${props.row.original.id}`}>
            <SettingOutlined className="!text-green-600 ml-2 mt-0" />
        </Link>
    </div>
);

const FilesHistoryColumn = [
    {
        Header: 'Tiers',
        accessor: 'tiersName',
        Cell: SourceCell,
    },
    {
        Header: 'Nom de fichier',
        accessor: 'fileName',
        Cell: FileCell,
    },
    {
        Header: 'Date de traitement',
        accessor: 'date',
    },
    {
        Header: 'Utilisateur',
        accessor: 'delivererName',
    },
];

const params: TableParams = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: [{ id: 'date', desc: false }],
    globalFilter: '',
};

export default FilesHistory;
