import React, { FunctionComponent } from 'react';
import { Spin } from 'antd';

interface css {
    width?: string;
    height?: string;
    top?: string;
    right?: string;
    left?: string;
    spinLeft?: string;
}

type CSS = {
    width: string;
    height: string;
    margin: string;
    background: string;
    display: string;
    zIndex: string;
    top?: string;
    right?: string;
    left?: string;
};

const Wait: FunctionComponent<css> = ({ width, height, top, right, left }) => {
    const customCSS: CSS = {
        width: width ? width : '100%',
        height: height ? height : '100%',
        margin: '0',
        background: ' rgba(112, 128, 144, 0.3)',
        display: 'flex',
        zIndex: '10',
        top: '97.6px',
    };
    if (top) customCSS.top = top;
    if (right) customCSS.right = right;
    if (left) customCSS.left = left;

    return (
        <div className="fixed" style={customCSS}>
            <div className="flex w-[100%] !pl-[45%]">
                <Spin className="!max-h-[100%]" tip="Chargement des données" size="large">
                    <div style={{ width: '100px' }} />
                </Spin>
            </div>
        </div>
    );
};

export default Wait;
