import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from 'components/organisms/Table/ReactTable/SyncTable';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import { HistoryKey } from 'models/CompanyBuilder/CompaniesList';
import Routes from 'constants/Routes';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Select } from 'antd';

const StatusCle = () => {
    const [statusCle, setStatusCle] = useState<HistoryKey[]>([]);
    const [copieData, setCopieData] = useState<HistoryKey[]>([]);

    const GetStatusCle = async () => {
        const response = await GET(`${API.LAST_HISTORY_KEYS}`);
        if (!response.status) return;
        setStatusCle(response.data);
        setCopieData(response.data);
    };

    function filtreArray(value: any[]) {
        if (value.length === 0) return setCopieData(statusCle);
        const arrayFiltre = [];
        for (let index = 0; index < statusCle.length; index++) {
            if (value.includes(statusCle[index].fk_etat)) arrayFiltre.push(statusCle[index]);
        }
        setCopieData(arrayFiltre);
    }

    useEffect(() => {
        GetStatusCle();
    }, []);

    const status = { 15: 'Rangé', 16: 'En chargement', 17: 'En livraison', 18: 'Perdu' };
    const statusSelect = [
        {
            value: 15,
            label: 'Rangé',
        },
        {
            value: 16,
            label: 'En chargement',
        },
        {
            value: 17,
            label: 'En livraison',
        },
        {
            value: 18,
            label: 'Perdu',
        },
    ];
    const columns = [
        {
            Header: 'Date',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <p>{dayjs(props.row.original.date).format('DD/MM/YY HH:mm')}</p>
                </Fragment>
            ),
        },
        {
            Header: 'Statut',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <p
                        className={classNames('text-sm  p-1', {
                            'text-green-500': props.row.original.couleur === 'success',
                            'text-blue-500': props.row.original.couleur === 'info',
                            'text-red-500': props.row.original.couleur === 'error',
                        })}
                    >
                        {status[props.row.original.fk_etat as keyof typeof status]}
                    </p>
                </Fragment>
            ),
        },
        {
            Header: 'Numero de clé',
            accessor: 'numero',
        },
        {
            Header: 'Pharmacie',
            accessor: 'RaisonSociale',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <span className="flex justify-between">
                        <Link to={`${Routes.COMPANY}/${props.row.original.idTiers}`} className="w-full">
                            <p className="text-sm text-purple-900 hover:underline">
                                {props.row.original.RaisonSociale}
                            </p>
                        </Link>
                    </span>
                </Fragment>
            ),
        },
        {
            Header: 'Dernier utilisateur',
            accessor: 'nomPrenom',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <span className="flex justify-between">
                        <Link to={`${Routes.USER}/${props.row.original.idUtilisateur}`} className="w-full">
                            <p className="text-sm text-purple-900 hover:underline">{props.row.original.nomPrenom}</p>
                        </Link>
                    </span>
                </Fragment>
            ),
        },
        {
            Header: 'Tournée',
            accessor: 'Libelle',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <span className="flex justify-between">
                        <Link to={`${Routes.TOUR}/${props.row.original.idTournee}`} className="w-full">
                            <p className="text-sm text-purple-900 hover:underline">{props.row.original.Libelle}</p>
                        </Link>
                    </span>
                </Fragment>
            ),
        },
        {
            Header: 'Derniere Livraison',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <span className="flex justify-between">
                        <Link to={`${Routes.DELIVERY}/${props.row.original.fk_livraison}`} className="w-full">
                            <p className="text-sm text-purple-900 hover:underline">{props.row.original.fk_livraison}</p>
                        </Link>
                    </span>
                </Fragment>
            ),
        },
    ];

    return (
        <section className="mx-10 my-5">
            <div className="flex flex-col ">
                <span className=" mb-2">Statut des clés</span>
                <Select
                    mode="multiple"
                    className="w-[15%]"
                    onChange={(value: number[]) => {
                        filtreArray(value);
                    }}
                    options={statusSelect}
                />
            </div>
            <Table columns={columns} data={copieData} />
        </section>
    );
};

export default StatusCle;
