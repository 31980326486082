import React, { useState, useEffect, useRef } from 'react';

interface TooltipCursorProps {
    content: string;
    children: React.ReactNode;
    delay: number;
}

const TooltipCursor: React.FC<TooltipCursorProps> = ({ children, content, delay }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showTooltipContent, setShowTooltipContent] = useState(false);

    const tooltipRef = useRef<HTMLDivElement>(document.createElement('div'));

    // ... component logic
    const handleMouseMove = (event: React.MouseEvent) => {
        const { clientX, clientY } = event;

        const tooltipWidth = tooltipRef.current?.offsetWidth || 0;
        const tooltipHeight = tooltipRef.current?.offsetHeight || 0;
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        //+12 is added to give a spice between cursor and tooltip
        let tooltipX = clientX + 12;
        let tooltipY = clientY + 12;

        // Check if tooltip exceeds the right side of the viewport
        if (tooltipX + tooltipWidth > viewportWidth) {
            tooltipX = clientX - tooltipWidth - 10;
        }

        // Check if tooltip exceeds the bottom of the viewport
        if (tooltipY + tooltipHeight > viewportHeight) {
            tooltipY = viewportHeight - tooltipHeight - 10;
        }

        setTooltipPosition({ x: tooltipX, y: tooltipY });
    };

    const handleMouseEnter = () => {
        setTooltipVisible(true);
        setShowTooltipContent(false);
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    };
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isTooltipVisible) {
            timeoutId = setTimeout(() => {
                setShowTooltipContent(true);
            }, delay);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isTooltipVisible, delay]);
    return (
        <div
            className="min-w-min"
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {isTooltipVisible && (
                <div
                    ref={tooltipRef}
                    className={`fixed p-3.5 bg-black text-white rounded-lg shadow      text-sm font-semibold max-w-md ${
                        showTooltipContent ? 'opacity-100' : 'opacity-0'
                    }`}
                    style={{
                        top: tooltipPosition.y,
                        left: tooltipPosition.x,
                        zIndex: '110',
                    }}
                >
                    {content}
                </div>
            )}
            {children}
        </div>
    );
};

export default TooltipCursor;
