import React, { Fragment } from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { QuestionOutlined } from '@ant-design/icons';

import Label from 'components/atoms/Text';
import TooltipCursor from '../Tooltip';

type Informations = {
    id: string;
    longitude: string | null;
    latitude: string | null;
    createAt: string;
    color: 'success' | 'secondary' | 'info' | 'warning' | 'none';
};

function Icone(informations: Informations) {
    let t: number | undefined | null;
    if (typeof informations.longitude == 'string') {
        t = parseInt(informations.longitude);
    } else {
        t = informations.longitude;
    }

    switch (t) {
        case null:
        case undefined:
            return <></>;

        case 0:
            return (
                <>
                    <TooltipCursor content="Coordonnées indisponibles car l'appareil ne les transmet pas" delay={200}>
                        <QuestionOutlined />
                    </TooltipCursor>
                </>
            );

        default:
            return (
                <a
                    className="text-sm text-purple-900 hover:underline"
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${informations.longitude},${informations.latitude}`}
                    rel="noreferrer"
                >
                    <FaMapMarkedAlt className="text-lg" />
                </a>
            );
    }
}

const LocationCell = (props: {
    value: string;
    row: {
        original: Informations;
    };
}) => {
    const verif: (string | null | undefined)[] = [null, undefined, '0.0', '0.000000'];
    return (
        <Fragment>
            <span className="flex justify-between">
                <span>
                    <Label text={props.value} color={props.row.original.color} />
                    <span className="text-sm pl-2">{props.row.original.createAt}</span>
                </span>
                <Icone {...props.row.original} />
            </span>
        </Fragment>
    );
};
export default LocationCell;
