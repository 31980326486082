import React, { FunctionComponent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { ColumnsType } from 'antd/lib/table';
import { Space, Table, DatePicker, Input, Select } from 'antd';
import local from 'antd/es/date-picker/locale/fr_FR';

import Routes from 'constants/Routes';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import { Link } from 'react-router-dom';
import { ModelDocument } from 'models/ModelDocument';
import useOptions from 'utils/Hook/GetOptions';

const { RangePicker } = DatePicker;

interface DestinatairesSourcesProps {
    id: string;
}

const DocumentsCompany: FunctionComponent<DestinatairesSourcesProps> = ({ id }) => {
    const { statusOptions } = useOptions({ getStatus: 'document' });
    const [documents, setDocuments] = useState<ModelDocument[]>([]);
    const [etat, setEtats] = useState<number[]>([]);
    const [copieData, setCopieData] = useState<any[]>([]);
    const [periode, setPeriode] = useState<string[]>([
        dayjs().set('month', 6).format('YYYY-MM-DD 00:00:00'),
        dayjs().format('YYYY-MM-DD 23:59:59'),
    ]);
    async function getDocumentsTiers() {
        let query = `idTiers=${id}&debutPeriode=${periode[0]}&finPeriode=${periode[1]}`;
        if (etat.length !== 0) query += `&etat=${etat}`;
        const response = await GET(`${API.DOCUMENTS_TIERS}?${query}`);
        setDocuments(response.data);
        setCopieData(response.data);
    }
    function arraySearch(value: any) {
        const filterTable = copieData.filter((o) =>
            Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())),
        );
        setDocuments(filterTable);
    }
    useEffect(() => {
        getDocumentsTiers();
    }, [periode, etat]);
    const columns: ColumnsType<ModelDocument> = [
        {
            title: 'Date / Heure',
            sorter: (a, b) => a.lastHistoryEtat.localeCompare(b.lastHistoryEtat),
            render: (text, props) => dayjs(props.lastHistoryEtat).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: 'Document',
            render: (text, props) => (
                <Link style={{ color: '#800080' }} to={`${Routes.DOCUMENTS}/${props.id_document}`}>
                    {props.numero_document}
                </Link>
            ),
        },
        {
            title: 'Livraison',
            render: (text, props) => (
                <Link style={{ color: '#800080' }} to={`${Routes.DELIVERY}/${props.idLivraison}`}>
                    {props.idLivraison}
                </Link>
            ),
        },
        {
            title: 'Tournée',
            render: (text, props) => (
                <Link style={{ color: '#800080' }} to={`${Routes.TOUR}/${props.idTournee}`}>
                    {props.libelleTournee}
                </Link>
            ),
        },
        {
            title: 'Sources',
            sorter: (a, b) => a.raisonSocialeTiersSource.localeCompare(b.raisonSocialeTiersSource),
            render: (text, props) => (
                <Link style={{ color: '#800080' }} to={`${Routes.COMPANY}/${props.fk_tiersSource}`}>
                    {props.raisonSocialeTiersSource}
                </Link>
            ),
        },
        {
            title: 'Livreur',
            sorter: (a, b) => a.raisonSocialeTiersSource.localeCompare(b.raisonSocialeTiersSource),
            render: (text, props) => <span>{props.nomPrenomLivreur}</span>,
        },
        {
            title: 'Etat',
            render: (text, props) => (
                <span
                    className={classNames('text-sm p-1', {
                        'text-green-500': props.couleur === 'success',
                        'text-purple-600': props.couleur === 'secondary',
                        'text-blue-500': props.couleur === 'info',
                        'text-orange-500': props.couleur === 'warning',
                        'text-red-500': props.couleur === 'error',
                    })}
                >
                    {props.Libelle_fk_etat}
                </span>
            ),
        },
        {
            title: 'Nombre de colis',
            render: (text, props) => <span>{props.countColis}</span>,
        },
    ];

    return (
        <Space direction="vertical" className="container mx-auto">
            <div className="flex items-center mt-5">
                <RangePicker
                    value={[dayjs(periode[0]), dayjs(periode[1])]}
                    format={'DD-MM-YYYY'}
                    locale={local}
                    onChange={(dates, dateStrings) => {
                        if (dateStrings[0])
                            setPeriode([
                                dateStrings[0].split('-').reverse().join('-') + ' 23:59:59',
                                dateStrings[1].split('-').reverse().join('-') + ' 00:00:00',
                            ]);
                        else
                            setPeriode([
                                dayjs().set('month', 6).format('YYYY-MM-DD 00:00:00'),
                                dayjs().format('YYYY-MM-DD 23:59:59'),
                            ]);
                    }}
                />
                <Select
                    allowClear
                    mode="multiple"
                    placeholder="Etat"
                    className="!w-60 !mr-5 !ml-5 !overflow-hidden"
                    options={statusOptions}
                    onChange={(value) => setEtats(value)}
                />
                <Input.Search
                    placeholder="Recherche"
                    style={{ width: '45%', marginRight: '5%' }}
                    onChange={arraySearch}
                />
            </div>

            <Table
                columns={columns}
                dataSource={documents}
                pagination={{
                    showTotal: (total: any, range: any) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight', 'bottomRight'],
                    total: documents?.length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
        </Space>
    );
};

export default DocumentsCompany;
