import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';

import Routes from 'constants/Routes';
import Label from 'components/atoms/Text';
import { SettingOutlined } from '@ant-design/icons';

const SourceCell = (props: { value: string; row: { original: { tiersSource_id: string } } }) => (
    <Fragment>
        <Link to={`${Routes.COMPANY}/${props.row.original.tiersSource_id}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const DestCell = (props: { value: string; row: { original: { tiersDest_id: string } } }) => (
    <Fragment>
        <Link to={`${Routes.COMPANY}/${props.row.original.tiersDest_id}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const NumberCell = (props: { value: string; row: { original: { id: string } } }) => (
    <Fragment>
        <Link to={`${Routes.DOCUMENTS}/${props.row.original.id}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const Tour = (props: {
    value: string;
    row: { original: { tour_id: string; fk_delivery: string; deliveryman_name: string } };
}) => (
    <Fragment>
        <Link to={`${Routes.TOUR}/${props.row.original.tour_id}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
        </Link>
        <Link to={`${Routes.DELIVERY}/${props.row.original.fk_delivery}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.row.original.fk_delivery}</p>
        </Link>
        <span>{props.row.original.deliveryman_name}</span>
    </Fragment>
);

const FileCell = (props: { value: string; row: { original: { fk_fichier: string; filename: string } } }) => (
    <div className="flex">
        <Link to={`${Routes.FILES_HISTORY}/${props.row.original.fk_fichier}`}>
            <p className="text-sm text-left text-purple-900 hover:underline">{props.row.original.filename}</p>
        </Link>
        {props.row.original.fk_fichier && (
            <Link to={`${Routes.FILES_HISTORYBRUT_ID}?${props.row.original.fk_fichier}`}>
                <SettingOutlined className="!text-green-600 ml-2 mt-0" />
            </Link>
        )}
    </div>
);

const StatusCell = (props: {
    value: string;
    row: {
        original: { color: 'success' | 'secondary' | 'info' | 'warning' | 'none'; longitude: string; latitude: string };
    };
}) => (
    <Fragment>
        <Label text={props.value} color={props.row.original.color} />
    </Fragment>
);

export const colisColumns = [
    {
        Header: 'Tiers source',
        accessor: 'tiersSourceName',
        Cell: SourceCell,
    },
    {
        Header: 'Tiers destination',
        accessor: 'tiersDestName',
        Cell: DestCell,
    },
    {
        Header: 'Tournée',
        accessor: 'tour_label',
        Cell: Tour,
    },
    {
        Header: 'Numéro',
        accessor: 'documentNumber',
        Cell: NumberCell,
    },
    {
        Header: 'Etat',
        accessor: 'statusLabel',
        Cell: StatusCell,
    },
    {
        Header: 'Nom de fichier',
        accessor: 'fileName',
        Cell: FileCell,
    },
    {
        Header: 'Nb colis',
        accessor: 'countPackage',
    },
];
