/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { FaFile, FaFlagCheckered, FaWarehouse } from 'react-icons/fa';

import Card from 'components/molecules/Card';
import Button from 'components/atoms/Buttons';
import Select from 'components/molecules/Inputs/Select';
import Table from 'components/organisms/Table/ReactTable';
import Notification from 'components/atoms/Notifications';
import LocationCell from 'components/atoms/LocationCell/index';

import API from 'constants/API';
import { Column } from 'react-table';
import Routes from 'constants/Routes';
import { GET, POST } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';
import { FormatPackage, PackageBuilder, PackageHistories } from 'models/PackageBuilder';
import useOptions from 'utils/Hook/GetOptions';
import dayjs from 'dayjs';

const Package = () => {
    const { id = '' } = useParams<{ id: string }>();
    const { state, dispatch } = useContext(NotificationContext);

    const { statusOptions } = useOptions({ getStatus: 'colis' });

    const [_package, setPackage] = useState(PackageBuilder());
    const [showSelect, setShowSelect] = useState<boolean>(false);
    const [changeStatus, setChangeStatus] = useState<{ label: string; value: string }>({ label: '', value: '' });

    const GetPackage = async () => {
        const { status, ...response } = await GET(API.PACKAGE + id);
        if (!status) return;
        setPackage(FormatPackage(response));
    };

    useEffect(() => {
        GetPackage();
    }, []);

    const onSubmit = async (data: { label: string; value: string }) => {
        const body = {
            fk_colis: +id,
            fk_etat: +data.value,
        };

        const { status, message } = await POST(API.PACKAGES_STATUS, body);
        if (!status) return dispatch({ message, status });
        dispatch({ message, status });
        GetPackage();
        setShowSelect(false);
    };

    return (
        <section className="container mx-auto my-5 px-2 grid grid-cols-1 lg:grid-cols-6 gap-5">
            <h2 className="text-2xl text-center lg:col-span-2">
                Colis <span className="text-blue-700">{_package.packageNumber}</span>
            </h2>
            <Card className="lg:col-span-2 lg:col-start-1" icon={FaFile} info={'Ajouté le ' + _package.createAt}>
                <Link to={Routes.DOCUMENTS + '/' + _package.documentId} className="text-purple-900 hover:underline">
                    {'Document ' + _package.documentNumber}
                </Link>
            </Card>
            <Card className="lg:col-span-2 lg:col-start-3" icon={FaWarehouse} info={'En provenance de'}>
                <Link to={Routes.COMPANY + '/' + _package.tiersSourceId} className="text-purple-900 hover:underline">
                    {_package.tiersSourceName}
                </Link>
            </Card>
            <Card className="lg:col-span-2 lg:col-start-5" icon={FaFlagCheckered} info={'À destination de'}>
                <Link to={Routes.COMPANY + '/' + _package.tiersDestId} className="text-purple-900 hover:underline">
                    {_package.tiersDestName}
                </Link>
            </Card>
            <div className="lg:col-start-1 lg:col-span-4 row-span-6 flex flex-col">
                <p>Historique de colis</p>
                <Table columns={columns} data={_package.packageHistories} />
            </div>
            <div className="py-5 lg:col-start-5 lg:col-span-2 ">
                <Notification message={state.message} status={state.status} />
                {showSelect && (
                    <>
                        <Select
                            name="status"
                            option={statusOptions}
                            className="py-2"
                            isMulti={false}
                            onChange={(e: { label: string; value: string }) => setChangeStatus(e)}
                        />
                        <Button
                            className="w-full flex items-center justify-center"
                            variant="secondary"
                            onClick={() => onSubmit(changeStatus)}
                            bold
                        >
                            Appliquer le changement
                        </Button>
                    </>
                )}
                <Button
                    type="button"
                    className="w-full flex items-center justify-center mt-5"
                    onClick={() => setShowSelect(!showSelect)}
                    bold
                >
                    {showSelect ? <p>Ne pas changer l&apos;état</p> : <p>Changer l&apos;état</p>}
                </Button>
            </div>
        </section>
    );
};

export default Package;

const columns: Column<PackageHistories>[] = [
    {
        Header: 'Date',
        accessor: 'createAt',
        Cell: (props: { value: string; row: { original: { deliveryId: string } } }) => (
            <span>{dayjs(props.value).format('DD/MM/YYYY HH:mm')}</span>
        ),
    },
    {
        Header: 'État',
        accessor: 'label',
        Cell: LocationCell,
    },
];
